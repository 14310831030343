.itemContainer
  position: relative
  max-width: 515px
  min-height: 300px
  width: 100%
  border-radius: 8px
  overflow: hidden
  cursor: pointer
  border: 1px solid var(--bg-item-opacity-border)
  
  @media screen and (max-width: 1400px)
    max-width: 400px

  @media screen and (max-width: 1100px)
    max-width: 600px

  .itemImg
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    object-fit: cover
    border-radius: 8px
    z-index: 0
    transition: opacity 0.3s ease
    -webkit-user-select: none
    -moz-user-select: none
    -ms-user-select: none
    user-select: none

  .itemContent
    position: absolute
    bottom: 0
    left: 0
    width: 100%
    height: 150px
    display: flex
    flex-direction: column
    justify-content: flex-start
    padding: 20px
    background-color: var(--bg-item-opacity-color)
    color: var(--text-color)
    border-radius: 0 0 8px 8px
    box-sizing: border-box
    transition: height 0.3s ease, opacity 0.3s ease
    
    @media screen and (max-width: 400px)
      height: 170px

    .itemTitle
      font-size: 24px
      font-weight: 400
      margin: 0

    .itemShortDesc
      display: flex
      justify-content: space-between
      gap: 10px
      margin-top: 20px

      .twoBoxDesc
        display: flex
        flex-direction: column
        gap: 10px

        .oneBoxDesc
          display: flex
          align-items: flex-start
          gap: 5px

          .itemDescImg
            width: 20px
            height: 20px
            object-fit: contain
            -webkit-user-select: none
            -moz-user-select: none
            -ms-user-select: none
            user-select: none

          .itemDescText
            font-size: 18px
            font-weight: 300
            color: var(--text-color-grey-black)

    .itemDesc
      font-size: 16px
      font-weight: 500
      display: block
      margin: 0
      opacity: 0
      height: 0
      overflow: hidden
      transition: opacity 0.3s ease, height 0.3s ease

  &:hover .itemContent
      height: 100%
      opacity: 1
  &:hover .itemDesc
      margin: 20px 0 0
      opacity: 1
      height: auto