.container
  display: flex
  justify-content: center
  align-items: center
  width: 100%
  padding: 120px 115px 90px
  
  @media screen and (max-width: 1200px)
    padding: 120px 86px 90px

  @media screen and (max-width: 1000px)
    padding: 120px 40px 90px

  @media screen and (max-width: 600px)
    padding: 120px 20px 90px

  @media screen and (max-width: 400px)
    padding: 120px 10px 90px

  .content
    max-width: 1920px
    width: 100%

    .title
      text-align: center
      font-size: 36px
      font-weight: 600
      color: var(--text-color)
      margin: 0 0 50px
        
      @media screen and (max-width: 600px)
        font-size: 28px

    .listCategory
      display: flex
      align-items: center
      justify-content: center
      gap: 30px

      @media screen and (max-width: 1800px)
        flex-wrap: wrap

      @media screen and (max-width: 1100px)
        flex-direction: column
        flex-wrap: nowrap

    .flexBox
      display: flex
      justify-content: center

      .seeMoreBtn
        display: flex
        justify-content: center
        text-align: center
        margin-top: 60px
        border-radius: 10px
        border: 1px solid var(--bg-btn-color)
        background-color: var(--bg-btn-color)
        padding: 15px 20px
        font-size: 20px
        font-weight: 500
        color: white
        cursor: pointer
        position: relative
        overflow: hidden
        transition: color 0.4s ease
        -webkit-user-select: none
        -moz-user-select: none
        -ms-user-select: none
        user-select: none

        &:before
          content: ''
          position: absolute
          top: -100%
          right: -200%
          width: 200%
          height: 500%
          background-color: var(--bg-color)
          transition: right 0.8s ease
          border-radius: 50%
          z-index: 0

        @media (hover: hover)
          &:hover 
            color: var(--text-color)

            &:before
              right: -25%
        
        @media (hover: none)
          &:active 
            color: var(--text-color)

            &:before
              right: -25%

        .btnText
          font-weight: 300
          font-size: 20px
          margin: 0
          position: relative
          z-index: 1
            