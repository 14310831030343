.container 
  display: flex
  justify-content: center
  align-items: center
  text-align: start
  width: 100%
  overflow: hidden
  padding: 35px 115px 45px

  @media screen and (max-width: 1200px)
    padding: 35px 86px 45px

  @media screen and (max-width: 1000px)
    padding: 35px 40px 45px

  @media screen and (max-width: 500px)
    padding: 35px 20px 45px

  @media screen and (max-width: 400px)
    padding: 35px 10px 45px

  .content
    display: flex
    align-items: center
    justify-content: space-between
    width: 100%
    min-height: 500px
    max-width: 1920px
    gap: 20px

    @media screen and (max-width: 1600px)
      justify-content: space-between
      
    .img
      max-width: 600px
      min-width: 400px
      width: 100%
      max-height: auto
      object-fit: contain
      z-index: 1
      -webkit-user-select: none
      -moz-user-select: none
      -ms-user-select: none
      user-select: none

      @media screen and (max-width: 1500px)
        display: none
  
    .textOverlay 
      color: var(--text-color)
      text-align: start
      z-index: 1
      font-size: 36px
      font-weight: 600
      max-width: 817px
      min-width: 280px
      width: 100%
      flex-shrink: 0
      
      @media screen and (max-width: 1500px)
        max-width: 100%
        flex-shrink: 1

      .textTitle
        font-size: 36px
        font-weight: 600
        margin: 0 0 25px 0

        @media screen and (max-width: 500px)
          text-align: center

      .blueSpan
        color: var(--text-color-blue)

      .textUnderTitle 
        display: flex
        flex-direction: row
        margin: 0 0 20px
        
        @media screen and (max-width: 500px)
          text-align: center
          align-items: center

        @media (max-width: 700px) 
          flex-direction: column
 
      .textPart 
        margin-right: 10px

      .textPartWithBlueSpan 
        display: flex
        align-items: center

        @media (max-width: 700px) 
          margin-top: 8px

    .bannerBtns
      display: flex
      gap: 20px
      margin: 0 0 30px

      @media screen and (max-width: 500px)
        text-align: center

      @media screen and (max-width: 600px)
        flex-direction: column

      .bannerBtnFirst
        display: flex
        justify-content: center
        padding: 10px
        border-radius: 5px
        background-color: var(--bg-btn-color)
        cursor: pointer
        border: 1px solid var(--bg-btn-color)
        position: relative
        overflow: hidden
        color: white
        transition: color 0.8s ease
        -webkit-user-select: none
        -moz-user-select: none
        -ms-user-select: none
        user-select: none

        &:before
          content: ''
          position: absolute
          top: -100%
          right: -200%
          width: 200%
          height: 500%
          background-color: var(--bg-color)
          transition: right 0.8s ease
          border-radius: 50%
          z-index: 0

        @media (hover: hover)
          &:hover
            color: var(--text-color)

            &:before
              right: -25%
        
        @media (hover: none)
          &:active
            color: var(--text-color)

            &:before
              right: -25%

      .bannerBtnSecond
        display: flex
        justify-content: center
        padding: 10px
        border-radius: 5px
        border: 1px solid var(--bg-btn-color)
        cursor: pointer
        background-color: transparent
        position: relative
        overflow: hidden
        transition: color 0.8s ease
        -webkit-user-select: none
        -moz-user-select: none
        -ms-user-select: none
        user-select: none

        &:before
          content: ''
          position: absolute
          top: -100%
          left: -200%
          width: 200%
          height: 500%
          background-color: var(--bg-btn-color)
          transition: left 0.8s ease
          border-radius: 50%
          z-index: 0

        @media (hover: hover)
          &:hover
            color: white

            &:before
              left: -25%
        
        @media (hover: none)
          &:active
            color: white

            &:before
              left: -25%

      .bannerBtnText
        font-weight: 300
        font-size: 20px
        margin: 0
        position: relative
        z-index: 1

    .bannerDesc
      display: flex 
      gap: 10px
      
      @media screen and (max-width: 500px)
        justify-content: center

      .descItem
        display: flex
        flex-direction: column

        .descText
          margin: 0
          font-weight: 300
          font-size: 16px
          color: var(--text-color)

        .imgBox
          display: flex
          gap: 5px
          align-items: center

          .descImg
            width: 20px
            height: 20px
            object-fit: contain
            -webkit-user-select: none
            -moz-user-select: none
            -ms-user-select: none
            user-select: none

          .descText
            font-weight: 600
            color: #5776B1



