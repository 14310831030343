.container
  display: flex
  justify-content: center
  align-items: center
  width: 100%
  padding: 56px 115px
  background-color: var(--bg-color-secondary)
  margin-top: 60px

  @media screen and (max-width: 1200px)
    padding: 56px 86px

  @media screen and (max-width: 1000px)
    padding: 56px 40px
  
  @media screen and (max-width: 600px)
    padding: 56px 20px
  
  @media screen and (max-width: 400px)
    padding: 56px 10px

  .content
    display: flex
    justify-content: space-between
    align-items: center
    gap: 60px
    max-width: 1920px
    width: 100%

    @media screen and (max-width: 1200px)
      flex-direction: column

    .text
      font-size: 24px
      font-weight: 600
      color: var(--text-color)
      margin: 0
      
      @media screen and (max-width: 600px)
        font-size: 20px

      @media screen and (max-width: 400px)
        font-size: 18px
            
      .blueText
        margin: 0
        color: var(--text-color-blue-secondary)

    .btn
      display: flex
      color: var(--text-color)
      padding: 20px 28px
      border: 2px solid var(--bg-btn-color)
      border-radius: 10px
      flex-shrink: 0
      cursor: pointer
      background-color: transparent
      position: relative
      overflow: hidden
      transition: color 0.8s ease
      -webkit-user-select: none
      -moz-user-select: none
      -ms-user-select: none
      user-select: none

      &:before
        content: ''
        position: absolute
        top: -100%
        left: -200%
        width: 200%
        height: 500%
        background-color: var(--bg-btn-color)
        transition: left 0.8s ease
        border-radius: 50%
        z-index: 0

      @media (hover: hover)
        &:hover
          color: white

          &:before
            left: -25%
      
      @media (hover: none)
        &:active
          color: white

          &:before
            left: -25%

      .btnText
        font-size: 24px
        font-weight: 600
        margin: 0
        position: relative
        z-index: 1
        
        @media screen and (max-width: 600px)
          font-size: 20px

        @media screen and (max-width: 400px)
          font-size: 18px
          