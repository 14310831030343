.loader
  position: fixed
  top: 0
  bottom: 0
  left: 0
  right: 0
  width: 100vw
  height: 100vh
  display: flex
  justify-content: center
  align-items: center
  z-index: 1000
  background: var(--bg-color)
  
.svg 
  width: 4em
  transform-origin: center
  animation: rotate4 2s linear infinite

  .circle 
    fill: none
    stroke: hsl(214, 97%, 59%)
    stroke-width: 2
    stroke-dasharray: 1, 200
    stroke-dashoffset: 0
    stroke-linecap: round
    animation: dash4 1.5s ease-in-out infinite

@keyframes rotate4 
  100% 
    transform: rotate(360deg)

@keyframes dash4 
  0% 
    stroke-dasharray: 1, 200
    stroke-dashoffset: 0

  50% 
    stroke-dasharray: 90, 200
    stroke-dashoffset: -35px
  
  100% 
    stroke-dashoffset: -125px