.container
  display: flex
  justify-content: flex-start
  align-items: center
  background-color: var(--bg-color-secondary)
  padding: 35px 0 65px 115px
  overflow: hidden

  @media screen and (min-width: 2150px)
    justify-content: center
    padding-left: 0

  @media screen and (max-width: 1200px)
    padding: 35px 86px 65px

  @media screen and (max-width: 1000px)
    padding: 35px 40px  65px
  
  @media screen and (max-width: 600px)
    padding: 35px 20px  65px

  @media screen and (max-width: 400px)
    padding: 35px 10px  65px

  .content
    display: flex
    justify-content: space-between
    align-items: center
    width: 100%
    position: relative
    max-width: 1920px
    width: 100%

    @media screen and (max-width: 1200px)
      flex-direction: column

    .textContent
      max-width: 630px
      min-width: 280px
      width: 100%
      flex-shrink: 0

      @media screen and (max-width: 1200px)
        flex-shrink: 1
        max-width: none

      .title
        color: var(--text-color)
        font-weight: 600
        font-size: 36px
        margin: 0 0 14px
        
        @media screen and (max-width: 600px)
          font-size: 28px

      .underTitleText
        color: var(--text-color)
        font-weight: 600
        font-size: 20px
        margin: 14px 0 0
        
        @media screen and (max-width: 600px)
          font-size: 18px
    
    .sliderContent
      max-width: 1500px
      min-width: 300px
      width: 100%
      
      @media screen and (max-width: 1600px)
        max-width: 600px
        
      @media screen and (max-width: 1200px)
        max-width: 1500px
        margin: 30px 0 20px

      @media screen and (max-width: 1000px)
        max-width: 600px

      .sliderItem
        padding: 0 12.5px
        
        @media screen and (max-width: 600px)
          padding: 0 5px

        .sliderItemContent
          display: flex
          flex-direction: column
          gap: 25px
          max-width: 550px
          min-width: 380px
          height: 200px
          background-color: var(--bg-scroll-element-color)
          border-radius: 10px
          padding: 15px 30px
          cursor: pointer

          @media screen and (max-width: 600px)
            min-width: 270px
            padding: 15px 
            height: 225px

          .sliderTitle
            display: flex
            align-items: center
            gap: 15px

            .imgDiv
              display: flex
              justify-content: center
              align-items: center
              padding: 15px
              width: 50px
              height: 50px
              border-radius: 5px
              background-color: var(--bg-scroll-element-img-color)

              img
                width: 35px
                height: 35px

            .sliderName
              margin: 0
              font-size: 24px
              font-weight: 600
              color: var(--text-color)
              
              @media screen and (max-width: 600px)
                font-size: 20px

          .sliderText
            font-size: 16px
            font-weight: 600
            color: var(--text-color)
            margin: 0