.container
  display: flex
  justify-content: center
  align-items: center
  width: 100%
  padding: 60px 115px 0
  overflow: hidden

  @media screen and (max-width: 1200px)
    padding: 120px 86px 0

  @media screen and (max-width: 1000px)
    padding: 120px 40px  0
  
  @media screen and (max-width: 600px)
    padding: 120px 20px  0
  
  @media screen and (max-width: 400px)
    padding: 120px 10px  0

  .content
    max-width: 1920px
    width: 100%

    .title
      color: var(--text-color)
      font-size: 36px
      font-weight: 600
      margin: 0 0 45px

      @media screen and (max-width: 600px)
        font-size: 28px
        text-align: center

    .contentList
      display: flex
      flex-wrap: wrap
      justify-content: space-between
      gap: 40px
      width: 100%

      .listItem
        display: flex
        align-items: center
        gap: 30px
        border-radius: 10px
        padding: 30px
        width: 31%
        
        @media screen and (max-width: 1700px)
          width: 48%

        @media screen and (max-width: 1400px)
          width: 47%

        @media screen and (max-width: 1200px)
          width: 100%

        @media screen and (max-width: 400px)
          flex-direction: column

        &:nth-child(1), &:nth-child(5), &:nth-child(6)
          background-color:  var(--bg-item-list-color)

        &:nth-child(1), &:nth-child(5), &:nth-child(6)
          .imgBox
            background-color: var(--box-img-bg-color)

        &:nth-child(2), &:nth-child(7)
          background-color: var(--bg-item-list-color-secondary)

          .text
            color: var(--text-color)
          
          .imgBox
            background-color: var(--box-img-bg-color-third)

        &:nth-child(3), &:nth-child(4), &:nth-child(8)
          background-color: var(--bg-item-list-color-third)

        &:nth-child(4), &:nth-child(5)
          width: 48%

          @media screen and (max-width: 1400px)
            width: 47%

          @media screen and (max-width: 1200px)
            width: 100%

        .imgBox
          width: 116px
          height: 116px
          background-color: var(--box-img-bg-color-secondary)
          border-radius: 10px
          padding: 10px
          flex-shrink: 0
          -webkit-user-select: none
          -moz-user-select: none
          -ms-user-select: none
          user-select: none

          .img
            width: 100%
            height: 100%

        .text
          color: white
          font-weight: 600
          font-size: 24px
          margin: 0

          @media screen and (max-width: 600px)
            font-size: 20px

          @media screen and (max-width: 400px)
            font-size: 18px
            