.container
  display: flex
  justify-content: center
  align-items: center
  width: 100%
  padding: 160px 115px
  overflow: hidden

  @media screen and (max-width: 1200px)
    padding: 160px 86px

  @media screen and (max-width: 1000px)
    padding: 160px 40px 
  
  @media screen and (max-width: 600px)
    padding: 100px 20px
  
  @media screen and (max-width: 400px)
    padding: 70px 10px

  .content
    display: flex
    align-items: stretch
    gap: 60px
    max-width: 1160px
    width: 100%

    @media screen and (max-width: 1000px)
      flex-direction: column
      gap: 40px

    .notFoundCode
      display: flex
      align-items: center

      @media screen and (max-width: 500px)
        justify-content: center

      .code
        color: #5776B1
        font-weight: 700
        font-size: 128px
        line-height: 1

    .line
      border-left: 1px solid var(--text-color)

      @media screen and (max-width: 1000px)
        border-top: 1px solid var(--text-color)
    
    .notFoundContent
      display: flex
      flex-direction: column
      gap: 20px

      @media screen and (max-width: 500px)
        text-align: center

      .title
        color: var(--text-color)
        font-weight: 700
        font-size: 36px

        @media screen and (max-width: 600px)
          font-size: 28px

      .text
        color: var(--text-color)
        font-weight: 700
        font-size: 24px

        @media screen and (max-width: 600px)
          font-size: 18px

        .linkText
          cursor: pointer
          color: #5776B1

    .flexBox
      display: flex
      justify-content: start

      @media screen and (max-width: 500px)
        justify-content: center

      .seeMoreBtn
        display: flex
        justify-content: center
        text-align: center
        border-radius: 10px
        border: 1px solid var(--bg-btn-color)
        background-color: var(--bg-btn-color)
        padding: 15px 20px
        font-size: 20px
        font-weight: 500
        color: white
        cursor: pointer
        position: relative
        overflow: hidden
        transition: color 0.4s ease
        -webkit-user-select: none
        -moz-user-select: none
        -ms-user-select: none
        user-select: none

        &:before
          content: ''
          position: absolute
          top: -100%
          right: -200%
          width: 200%
          height: 500%
          background-color: var(--bg-color)
          transition: right 0.8s ease
          border-radius: 50%
          z-index: 0

        @media (hover: hover)
          &:hover 
            color: var(--text-color)

            &:before
              right: -25%
        
        @media (hover: none)
          &:active 
            color: var(--text-color)

            &:before
              right: -25%

        .btnText
          font-weight: 300
          font-size: 20px
          margin: 0
          position: relative
          z-index: 1
            