$error-display-time: 10s
$progress-duration: 9s 

.errorNotification
  position: relative
  background-color: #f8d7da
  border-left: 4px solid #f44336
  color: #721c24
  padding: 16px
  border-radius: 8px
  display: flex
  align-items: center
  transition: background-color 0.3s ease-in-out
  cursor: pointer
  animation: slideInOut $error-display-time ease-in-out
  opacity: 0
  width: 100%
  max-width: 320px
  overflow: hidden
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2)

  &:hover
    background-color: #f5c6cb
    transform: scale(1.05)

  .icon
    height: 20px
    width: 20px
    margin-right: 8px
    flex-shrink: 0
    color: #dc3545

  .errorContent
    display: flex
    flex-direction: column
    gap: 10px
    font-size: 14px
    font-weight: 600
    max-width: 100%
    width: 100%

    .errorCodeStatus
      display: flex
      justify-content: space-between
      gap: 10px

  .progressBar
    position: absolute
    bottom: 0
    left: 0
    height: 4px
    z-index: 91
    background-color: #f44336
    animation: fillProgress $progress-duration linear forwards

@keyframes slideInOut
  0%
    opacity: 0
    transform: translateX(100%)
  10%
    opacity: 1
    transform: translateY(0)
  90%
    opacity: 1
    transform: translateY(0)
  100%
    opacity: 0
    transform: translateX(100%)

@keyframes fillProgress
  from
    width: 0 
  to
    width: 100%
