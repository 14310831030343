@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300..700&display=swap');

* {
  box-sizing: border-box;
  font-family: Comfortaa, sans-serif;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--bg-color);
}

body::-webkit-scrollbar {
  width: 8px;
}

body::-webkit-scrollbar-track {
  background: var(--scrollbar-body-track-color);
}

body::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-body-thumb-color);
  border-radius: 5px;
  border: 1px solid var(--scrollbar-body-track-color);
}

a {
  all: unset;
  color: inherit;
  cursor: pointer;
  text-decoration: none;
}

a:hover,
a:focus,
a:active {
  all: unset;
}
