.container
  display: flex
  justify-content: center
  align-items: center
  width: 100%
  padding: 120px 115px 10px

  @media screen and (max-width: 1200px)
    padding: 120px 86px 10px

  @media screen and (max-width: 1000px)
    padding: 120px 40px 10px
  
  @media screen and (max-width: 600px)
    padding: 120px 20px 10px
  
  @media screen and (max-width: 400px)
    padding: 120px 10px 10px

  .content
    max-width: 1920px
    width: 100%

    .title
      font-size: 36px
      font-weight: 600
      margin: 0 0 60px
      color: var(--text-color)

      @media screen and (max-width: 600px)
        font-size: 28px
        text-align: center
    
    .QandAContainer 
      display: flex
      flex-direction: column
      max-height: 450px
      gap: 30px
      overflow-y: scroll
      padding-right: 50px

      &::-webkit-scrollbar 
        width: 5px

      &::-webkit-scrollbar-thumb 
        background-color: var(--scrollbar-thumb-color)
        border-radius: 10px

      &::-webkit-scrollbar-track 
        background: var(--scrollbar-track-color)
        border-radius: 10px

      @media screen and (max-width: 700px) 
        padding-right: 0

        &::-webkit-scrollbar 
          width: 0

        &::-webkit-scrollbar-thumb 
          background-color: transparent

      .QandABox
        display: flex
        flex-direction: column
        gap: 20px

        .questionBox
          display: flex
          justify-content: space-between
          align-items: center
          gap: 10px
          cursor: pointer 

          .question
            font-size: 24px
            font-weight: 600
            color: var(--text-color)
            margin: 0

            @media screen and (max-width: 600px)
              font-size: 20px
        
          .imgBox
            width: 50px
            height: 50px
            min-width: 50px
            min-height: 50px
            display: flex
            justify-content: center
            align-items: center
            background-color: var(--bg-arrow-color)
            border-radius: 5px
            -webkit-user-select: none
            -moz-user-select: none
            -ms-user-select: none
            user-select: none

            .img
              width: 100%
              height: 100%
              transition: transform 0.3s ease 

          .rotate
            transform: rotate(90deg)

        .answer
          font-size: 20px
          font-weight: 600
          color: var(--text-slider-color)
          margin: 0
          
          @media screen and (max-width: 600px)
            font-size: 18px

        .line
          border: 1px solid var(--text-color)