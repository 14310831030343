.container
  width: 100%
  background: var(--bg-color-secondary)
  padding: 35px 115px 80px

  @media screen and (max-width: 1200px)
    padding: 35px 86px 80px

  @media screen and (max-width: 1000px)
    padding: 35px 40px  80px
  
  @media screen and (max-width: 600px)
    padding: 35px 20px  80px

  @media screen and (max-width: 400px)
    padding: 35px 10px  80px

  .content
    max-width: 1920px
    width: 100% 
    margin: 0 auto

    .sliderContent
      color: var(--text-color)
      padding: 0 160px

      @media screen and (max-width: 1500px)
        max-width: 950px
        padding: 0

      .sliderItem
        padding: 0 10px

        .flexRow
          display: flex
          justify-content: space-between
          align-items: center
          gap: 5px
          margin: 0 0 10px
          
          @media screen and (max-width: 500px)
            flex-direction: column
            align-items: start
            gap: 10px

          .userName
            font-weight: 600px
            font-size: 35px
            margin: 0

            @media screen and (max-width: 650px)
              font-size: 24px 

          .dateBox
            position: relative
            display: flex
            align-items: center
            gap: 10px

            &:hover .tooltip 
              top: calc( 100% + 10px )
              opacity: 1
              visibility: visible
              pointer-events: auto
            
            .tooltip
              position: absolute
              top: 0
              left: 50%
              transform: translateX(-50%)
              font-size: 14px
              background-color: #2F3746
              color: #fff
              padding: 6px 8px
              border-radius: 5px
              box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1)
              opacity: 0
              pointer-events: none
              transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55)
              z-index: 1
              white-space: nowrap
            
            .tooltip::before 
              position: absolute
              content: ""
              height: 8px
              width: 8px
              background-color: #2F3746
              top: -4px
              left: 50%
              transform: translate(-50%) rotate(45deg)
              transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55)

            .dateImg
              width: 27px
              height: 27px

            .date
              font-weight: 500px
              font-size: 24px
              color: var(--text-slider-color)
              margin: 0
              
              @media screen and (max-width: 650px)
                font-size: 18px

          .starsBox
            position: relative
            display: flex
            gap: 6px
            font-size: 19px
            
            @media screen and (max-width: 650px)
              font-size: 18px
              
            &:hover .tooltip 
              bottom: calc( 100% + 10px )
              opacity: 1
              visibility: visible
              pointer-events: auto
            
            .tooltip
              position: absolute
              bottom: 0
              left: 50%
              transform: translateX(-50%)
              font-size: 14px
              background-color: #2F3746
              color: #fff
              padding: 6px 8px
              border-radius: 5px
              box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1)
              opacity: 0
              pointer-events: none
              transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55)
              z-index: 1
            
            .tooltip::before 
              position: absolute
              content: ""
              height: 8px
              width: 8px
              background-color: #2F3746
              bottom: -4px
              left: 50%
              transform: translate(-50%) rotate(45deg)
              transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55)

          .category
            font-weight: 500px
            font-size: 20px
            color: var(--text-slider-color)
            margin: 0
            
            @media screen and (max-width: 650px)
              font-size: 18px

        .text
          font-size: 25px
          font-weight: 500px
          margin: 0

          @media screen and (max-width: 650px)
            font-size: 18px