.sliderContent
  margin: 0
  
  .slick-dots li button:before
    font-size: 10px
    color: var(--text-color)

  .slick-dots li button:hover:before, .slick-dots li button:focus:before
    color: white
      
  .slick-dots li
    margin: 0

    &:not(:first-child):not(:last-child)
      margin: 0 3px

  .slick-dots li.slick-active button:before
    color: white

  .slick-slider,
  .slick-list 
    position: inherit

    @media screen and (max-width: 1200px)
      position: relative

  .slick-dots
    text-align: start
    width: auto
    bottom: -35px
    left: 0

    @media screen and (max-width: 1200px)
      bottom: -40px
      text-align: center
      width: 100%